import React from 'react'
import Dashboard from './Dashboard'

function OwnerMenu() {
  return (
    <div>
        <Dashboard />
        OwnerMenu
    </div>
  )
}

export default OwnerMenu