import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import image from '../Images/order.png';


export default function ThankYou() {
    return(
            <Card className="text-center bg-dark text-white">
                <Card.Body>
                <Card.Img src={image} alt="Card image" style={{ maxWidth: '40%', maxHeight: '40%', margin: 'auto' }} />

                    <Card.Title className="display-5">Thank You</Card.Title>
                    <Card.Text className="lead">
                        We truly appreciate your business and look forward to serving you again in the future.
                    </Card.Text>
                    <Link to="/home">
                    <Button style={{marginBottom:'70px'}} variant="primary">Go to Home</Button>
                    </Link>
                </Card.Body>
            </Card>
    )
}
