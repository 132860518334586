import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Axios from "axios";

function DeleteButton(props) {
    const [showModal, setShowModal] = useState(false);
    const user = props.user;
    const [id, setId] = useState(user.id);


    const handleDelete = (e) => {
        e.preventDefault();
        console.log("function called");
        Axios.delete(`http://localhost:8082/users/${id}`, {

        }).then(async (response) => {
            console.log("calling cartItem Fetch API: ")
            console.log(id)
            const response1 = await Axios.get(`http://localhost:8082/cartFetchSingleByUserId/${id}`)
            console.log("response1.data.data: "+ response1.data.data)
            if(response1.data.data!=null){
                Axios.delete(`http://localhost:8082/cartDeleteByUserId/${user.id}`)
            }

            alert("User Deleted Successfully");
            
            window.location.reload();
        });

    // Close the modal
    setShowModal(false);
    };


return (
    <>
        <Button variant="danger" onClick={() => setShowModal(true)}>
            Delete
        </Button>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    </>
);
}

export default DeleteButton