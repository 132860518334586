import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from "axios";

const CashierStatusButton = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [orderStatus, setOrderStatus] = useState('pending'); // Default status
  const [orderItemList, setOrderItemList] = useState([]);
  const [productsFiltered, setProductsFiltered]  = useState([]);
  const order = props.order;
  console.log("order ....",order)

  const getStatusString = (status) => {
    switch (status) {
      case 1:
        return 'Accepted';
      case 2:
        return 'Declined';
      default:
        return 'Pending';
    }
  };

  useEffect(() => {

    axios.get(`http://localhost:8082/orderItemFetchByOrderId/${order.id}`)
      .then(orderItemResponse => {
        setOrderItemList(orderItemResponse.data.data);            
        const productIds = orderItemResponse.data.data
        .map(orderItem => orderItem.productId);
        
        console.log("product ids", productIds)

        axios.get(`http://localhost:8082/product`)
          .then(productResponse => {
            const filteredProducts = productResponse.data.data
              .filter(product => productIds.includes(product.id))
              .map(product => {
                const orderItem = orderItemResponse.data.data.find(info => info.productId === product.id);
                return {
                  ...product,
                  orderItemId: orderItem ? orderItem.id : null,
                  orderQuantity: orderItem ? orderItem.quantity : null,
                  orderPrice: orderItem ? orderItem.price : null,
                };
              });
            setProductsFiltered(filteredProducts);
          })
          .catch(productError => {
            console.error("Error fetching product data:", productError);
          });
      })
      .catch(orderItemError => {
        console.error("Error fetching cart item data:", orderItemError);
      });
  }, []);

  //console.log("products order filtered", productsFiltered)

  const handleAccept = async => {
    try {
      // Make the Axios request to update the order status
        axios.put(`http://localhost:8082/orderUpdate/${order.id}`, {
        id: order.id,
        userId: order.userId,
        sessionId: order.sessionId,
        token: order.token,
        status: 1,
        subTotal: order.subTotal,
        itemDiscount: order.itemDiscount,
        tax: order.tax,
        shipping: order.shipping,
        total: order.total,
        promo: order.promo,
        discount: order.discount,
        grandTotal: order.grandTotal,
        //createdAt: Date.now(),
        firstName: order.firstName,
        middleName: order.middleName,
        lastName: order.lastName,
        mobile: order.mobile,
        email: order.email
      });
  
      // Update the state after the request is successful
      setOrderStatus("Accepted")
      setShowModal(false);
    } catch (error) {
      console.error('Error updating order status:', error);
      // Handle the error appropriately, e.g., show a notification to the user
    }
  };
  

  const handleDecline = () => {
    setOrderStatus("Decline")
    setShowModal(false);
  };


  const openModal = () => {
    setShowModal(true);
  };

  const renderOrderItems = () => {
    return (
      <ul>
        {productsFiltered.map((item, index) => (
          <li key={index}>{item.title} - {item.orderQuantity} - ${item.orderPrice}</li>
        ))}
      </ul>
    );
  };

  return (
    <div>
      <Button onClick={openModal}>View</Button>
      <p>Status: {getStatusString(order.status)}</p>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Order Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Do you want to accept or decline this order?</p>
          {renderOrderItems()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDecline}>
            Decline
          </Button>
          <Button variant="primary" onClick={handleAccept}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default CashierStatusButton;


