import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaShoppingCart } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from "react-bootstrap/Image";
import admin from "../Images/admin.png";
import axios from "axios";

import { ROLE_ID } from "../globalConstants";
import { Badge, Button, Dropdown, FormControl} from "react-bootstrap";
import { CartState } from '../Reducers/Context';
import MyOrders from "./MyOrders";

export default function Dashboard() {

  const adminRoleId = ROLE_ID.Admin
  const customerRoleId = ROLE_ID.Customer;
  const ownerRoleId = ROLE_ID.Owner;
  const cashierRoleId = ROLE_ID.Cashier;

  const [cartData, setCartData] = useState([]);
  const [cartItemData, setCartItemData] = useState([]);
  const [productData, setProductData] = useState([]);

  const {
    state: { cart },
    dispatch,
    productDispatch,
  } = CartState();

  

  console.log("cart", cart);
  var userId = sessionStorage.getItem("userId");

  useEffect(() => {
    // Fetch cart data here using Axios or any other method
    // Example:
    axios.get(`http://localhost:8082/cartFetchSingleByUserId/${userId}`)
      .then(response => {
        setCartData(response.data);
        sessionStorage.setItem("cartId", response.data.data.id);
        console.log("cart hi ", response.data);
      })
      .catch(error => {
        console.error("Error fetching cart data:", error);
      });
  }, []);
  

  useEffect(() => {
    var cartId = parseInt(sessionStorage.getItem("cartId"), 10);
  
    // Fetch cart item data
    axios.get(`http://localhost:8082/cartItemFetchByCartId/${cartId}`)
      .then(cartItemResponse => {
        setCartItemData(cartItemResponse.data.data)
        // Extract product IDs from cart items
        const productIds = cartItemResponse.data.data
        .filter(cartItem => cartItem.active == 0)
        .map(cartItem => cartItem.productId);
  
        // Fetch products and filter by product IDs from cart items
        axios.get(`http://localhost:8082/product`)
          .then(productResponse => {
            // Filter products based on product IDs from cart items
            const filteredProducts = productResponse.data.data.filter(product => productIds.includes(product.id));
  
            // Set filtered products to productData state
            setProductData(filteredProducts);
            console.log("filtered products", filteredProducts);
          })
          .catch(productError => {
            console.error("Error fetching product data:", productError);
          });
      })
      .catch(cartItemError => {
        console.error("Error fetching cart item data:", cartItemError);
      });
  }, []);

  console.log("cart", cart);

  // const addToCart = () => { 
  //   var cartItem = {
  //     productId: 1,
  //     cartId: 2,
  //     sku: "100",
  //     price: 1,
  //     discount: 1,
  //     quantity: 1,
  //     active: true,
  //     createdAt: "0001-01-01T00:00:00Z",
  //     content: "1",
  //   };
    
  //   cart.map((cartlist) => {
  //     console.log("Content " + cartItem.content);
  //     cartItem.productId = cartlist.id;
  //     cartItem.cartId = parseInt(sessionStorage.getItem("cartId"), 10);
  //     cartItem.sku = cartlist.sku;
  //     cartItem.price = cartlist.price;
  //     cartItem.discount = cartlist.discount;
  //     cartItem.quantity = cartlist.quantity;
  //     cartItem.active = cartlist.active;
  //     cartItem.content = cartlist.content;

  //     //console.log("Adding Cart:" + cartItem);
  //     // Perform the POST request to create a new user

  //     const isProductInCart = cartItemData.some(cartItem => cartItem.productId === cartlist.id && cartItem.cartId === parseInt(sessionStorage.getItem("cartId"), 10));
      
  //     if (isProductInCart != true) {
  //       axios.post("http://localhost:8082/cartItemCreate", cartItem)
  //       .then((response) => {
  //         console.log("Cart Item created :", response.data);
  //       })
  //       .catch((error) => {
  //         console.error("Error creating user:", error);
  //       });
  //     }
      
  //   });
  // };

  const location = useLocation();
  console.log(customerRoleId)

  const roleId = JSON.parse(sessionStorage.getItem('roleId'));
  //console.log(roleId)
  const navigate = useNavigate();
  const menu = () => {
    navigate('/home');
  }
  const myOrders = () => {
    navigate('/MyOrders');
  }
  const logout = () => {
    navigate('/');
    // sessionStorage.clear();
  }
  if (adminRoleId === roleId) {
    return (
      <div>
        <Navbar bg="primary" data-bs-theme="dark">
        <Container>
          <Nav className="me-auto">
            <Nav.Link className="text-white" style={{textTransform: 'uppercase', fontFamily: 'sans-serif'}} href="/users">QDine</Nav.Link>
            <Nav.Link className="text-white" style={{textTransform: 'uppercase', fontFamily: 'sans-serif'}} href="/users">Users</Nav.Link>
          </Nav>

          <Nav className="justify-content-end">
        <Nav.Item>
          <Nav.Link onClick={logout} className="d-flex align-items-center">
          <span className="me-2 text-white" style={{textTransform: 'uppercase', fontFamily: 'sans-serif'}}>Logout</span>
          <Image src={admin} width={36} height={36} className="ml-10 rounded-circle" />
          </Nav.Link>
        </Nav.Item>
        </Nav>
        </Container>
      </Navbar>
      </div>
    );
  }
  else if (customerRoleId === roleId) {
    return (
      <div>
        <Navbar bg="primary" data-bs-theme="dark">
          <Container>
          <Navbar className="me-auto" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
    <Navbar.Brand onClick={menu} style={{ textTransform: 'uppercase', fontFamily: 'sans-serif'}}>QDine</Navbar.Brand>
    <Nav.Link onClick={menu} className="text-white" style={{textTransform: 'uppercase', fontFamily: 'sans-serif', marginTop:'8px'}}>Menu</Nav.Link>
    <Nav.Link onClick={myOrders} className="text-white" style={{textTransform: 'uppercase', fontFamily: 'sans-serif', marginTop:'8px',marginLeft:'10px'}}>My Orders</Nav.Link>
  </div>
  {location.pathname.split("/")[1] !== "cart" && (
    <Navbar.Text className="search" style={{ height: '50px', display: 'flex', alignItems: 'center', marginLeft: '20px', marginRight: '20px' }}>
      <FormControl
        style={{ width: 500 }}
        type="search"
        placeholder="Search a product..."
        className="m-auto"
        aria-label="Search"
        onChange={(e) => {
          productDispatch({
            type: "FILTER_BY_SEARCH",
            payload: e.target.value,
          });
        }}
      />
    </Navbar.Text>
  )}
             <Dropdown alignRight>
              <Dropdown.Toggle variant="success">
                <FaShoppingCart color="white" fontSize="25px" />
                <Badge style={{
                  position: 'absolute',
                  top: '-9px',
                  right: '-5px',
                  backgroundColor: 'red',
                  color: 'white'
                }}>{productData.length}</Badge>
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ minWidth: 370 }}>
                {productData.length > 0 ? (
                  <>
                    {productData.map((prod) => (
                      <Dropdown.Item key={prod.id}>
                        <div className="cartItem">
                          <img
                            src={prod.slug}
                            className="cartItemImg"
                            alt={prod.title}
                          />
                          <div className="cartItemDetail">
                            <span>{prod.title}</span>
                            <span className="float-end">$ {prod.price}</span>
                          </div>
                        </div>
                      </Dropdown.Item>
                    ))}
                    <Link to="/cart">
                      <Button style={{ width: "95%", margin: "10px 10px 0 10px" }}>Go To Cart</Button>
                    </Link>
                  </>
                ) : (
                  <Link to="/cart"> </Link>
                )}
              </Dropdown.Menu>
            </Dropdown>
            </Navbar>
          <Nav className="justify-content-end">
              <Nav.Link onClick={logout}>Logout</Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      </div>
    );
  }
  else if (cashierRoleId === 4){
    return (
      <div>
         <Navbar bg="primary" data-bs-theme="dark">
        <Container>
          <Nav className="me-auto">
            <Nav.Link className="text-white" style={{textTransform: 'uppercase', fontFamily: 'sans-serif'}} href="/CashierPage">QDine</Nav.Link>
            <Nav.Link className="text-white" style={{textTransform: 'uppercase', fontFamily: 'sans-serif'}} href="/CashierPage">Orders</Nav.Link>
            <Nav.Link onClick={logout}>Logout</Nav.Link>
          </Nav>
          </Container>
          </Navbar>
      </div>
    );
  }
  else if (ownerRoleId === roleId){
    return (
      <div>
        <Navbar bg="primary" data-bs-theme="dark">
          <Container>
            <Navbar.Brand href="#">QDine</Navbar.Brand>
            <Nav className="me-auto">

              <Nav.Link href="/OwnerMenu">Owner Menu</Nav.Link>
              <Nav.Link href="/ChefMenu">Chef Menu</Nav.Link>
              <Nav.Link onClick={logout}>Logout</Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      </div>
    );
  }
}