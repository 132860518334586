import React, { useState } from "react";
import Axios from "axios";
import Dashboard from "./Dashboard";
import { useNavigate } from 'react-router-dom';


export default function UserRegister() {
  const navigate = useNavigate();

  const [id, setId] = useState("");
  const [roleId, setRoleId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [registeredAt, setRegisteredAt] = useState("");
  const [lastLogin, setLastLogin] = useState("");
  const [intro, setIntro] = useState("");
  const [profile, setProfile] = useState("");
  const [userType, setUserType] = useState("");

  const registerUser = (e) => {
    e.preventDefault();
    let selectedRoleId;
    console.log("function called");

    if (userType == "customer") {
      selectedRoleId = 2;
    } else if (userType == "chef") {
      selectedRoleId = 3; 
    }

    Axios.post("http://103.186.185.127:8082/users", {
      roleId: selectedRoleId,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      mobile: mobile,
      email: email,
      Password: Password,
      registeredAt: Date.now(),
      lastLogin: Date.now(),
      intro: intro,
      profile: profile,
    }).then((response) => {
      alert("User Added Successfully");
      clearAll();
    });
  };

  const clearAll = () => {
    setUserType('');
    setFirstName('');
    setMiddleName('');
    setLastName('');
    setMobile('');
    setEmail('');
    setPassword('');
    setIntro('');
    setProfile('');
  }

  const closeForm = () => {
    navigate('/Users');
  };

  return (
    <div className="min-h-screen">
      <Dashboard />
      <div className="mt-3 container-fluid soft-gradient-bg">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6">
            <div className="background-gradient rounded-5 shadow p-5 mb-4">
              <form>
                <h1 className="custom-font-family mb-4 text-center">ADD NEW USER</h1>
                <select
                  className="form-control mb-4"
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                >
                  <option value="">Select User Type</option>
                  <option value="customer">Customer</option>
                  <option value="chef">Chef</option>
                </select>
                <input type="text" className="hover-pop-effect form-control mb-4" name="fullname"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name" />
                <input type="text" className="hover-pop-effect form-control mb-4" name="middlename"
                  value={middleName}
                  onChange={(e) => setMiddleName(e.target.value)}
                  placeholder="Middle Name" />
                <input type="text" className="hover-pop-effect form-control mb-4" name="lastname"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name" />
                <input type="text" className="hover-pop-effect form-control mb-4" name="mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  placeholder="Mobile" />
                <input type="text" className="hover-pop-effect form-control mb-4" name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email" />
                <input type="password" className="hover-pop-effect form-control mb-4" name="Password"
                  value={Password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password" />
                <input type="text" className="hover-pop-effect form-control mb-4" name="intro"
                  value={intro}
                  onChange={(e) => setIntro(e.target.value)}
                  placeholder="Intro" />
                <input type="text" className="hover-pop-effect form-control mb-4" name="profile"
                  value={profile}
                  onChange={(e) => setProfile(e.target.value)}
                  placeholder="Profile" />

                <div className="d-flex justify-content-center">
                  <button onClick={(e) => registerUser(e)} style={{ width: '300px' }} className="btn btn-primary p-2 me-2">Add User</button>
                  <button type="button" onClick={closeForm} style={{ width: '300px' }} className="btn btn-danger p-2">Close</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
