import { Card, Button, Row, Col, Badge } from "react-bootstrap";
import { CartState } from '../Reducers/Context';
import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaColumns } from "react-icons/fa";


const SingleProduct = ({ prod }) => {
  const {
    state: { cart },
    dispatch,
  } = CartState();

  const [cartData, setCartData] = useState([]);
  const [cartItemData, setCartItemData] = useState([]);
  const [addedToCart, setAddedToCart] = useState(false); //

  console.log("cart", cart);


  useEffect(() => {
    var cartId = parseInt(sessionStorage.getItem("cartId"), 10);
  
    // Fetch cart item data
    axios.get(`http://localhost:8082/cartItemFetchByCartId/${cartId}`)
      .then(cartItemResponse => {
        setCartItemData(cartItemResponse.data.data)
        })
      .catch(cartItemError => {
        console.error("Error fetching cart item data:", cartItemError);
      });
  }, []);

  const addToCart = (prod) => {
    const cartItem = {
      productId: prod.id,
      cartId: parseInt(sessionStorage.getItem("cartId"), 10),
      sku: prod.sku,
      price: prod.price,
      discount: prod.discount,
      quantity: prod.quantity,
      active: false,
      content: prod.content,
    };

      const isProductInCart = cartItemData.some(
        (cartItem) =>
          cartItem.active === 0 &&
          cartItem.productId === prod.id &&
          cartItem.cartId === parseInt(sessionStorage.getItem("cartId"), 10)
      );
    
      if (!isProductInCart) {
        axios.post("http://localhost:8082/cartItemCreate", cartItem)
          .then((response) => {
            console.log("Cart Item created :", response.data);
            setAddedToCart(true);
          })
          .catch((error) => {
            console.error("Error creating user:", error);
          });
      }

    };
  


  return (
<Card className="ml-3 mt-3 mb-4 shadow-sm custom-card-size">
  <Card.Body>
    <Row className="align-items-center">
      <Col xs={12} md={4}>
        <Card.Img variant="top" src={prod.slug} alt={prod.title} />
      </Col>
      <Col xs={12} md={5}>
        <Card.Title>{prod.title}</Card.Title>
        <Card.Subtitle>
          <Badge bg="secondary" className="me-2">$ {prod.price}</Badge>
        </Card.Subtitle>
      </Col>
      <Col xs={12} md={3}>
        {cartItemData.some((p) => p.productId === prod.id && p.active == 0) ? (
          <Button variant="secondary" disabled>Added to Cart</Button>
        ) : (
          <Button variant="primary" onClick={() => addToCart(prod)}>Add to Cart</Button>
        )}
        {addedToCart && <p className="text-success mt-2">Item added to cart!</p>}
      </Col>
    </Row>
  </Card.Body>
</Card>
  );
};

export default SingleProduct;