import React from 'react'
import axios from 'axios'
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";


export default function DeleteItem(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const product = props.product;

    function deleteItem(cartItemId) {
      
        axios.delete(`http://localhost:8082/cartItemDelete/${cartItemId}`)
          .then(response => {
            console.log('Item deleted successfully:', response.data);
            handleClose ();
            window.location.reload();
            // Handle any further actions after successful deletion
          })
          .catch(error => {
            console.error('Error deleting item:', error);
            // Handle error cases, such as showing error messages to the user
          });
      }
      
  return (
    <div>
        <Button type="button" variant="light" onClick={handleShow}>
            <AiFillDelete fontSize="20px" />
        </Button>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Do you really want to delete this item?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{product.slug}</p>
            <p>{product.title}</p> 
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteItem.bind(null, product.cartItemId)}>
            Delete Item
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
