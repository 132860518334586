import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from "axios";

function OrderViewButton(props) {
  const [showModal, setShowModal] = useState(false);
  const [orderItemList, setOrderItemList] = useState([]);
  const [productsFiltered, setProductsFiltered]  = useState([]);
 const order = props.order;
  useEffect(() => {

    axios.get(`http://localhost:8082/orderItemFetchByOrderId/${order.id}`)
      .then(orderItemResponse => {
        setOrderItemList(orderItemResponse.data.data);            
        const productIds = orderItemResponse.data.data
        .map(orderItem => orderItem.productId);
        
        //console.log("product ids", productIds)

        axios.get(`http://localhost:8082/product`)
          .then(productResponse => {
            const filteredProducts = productResponse.data.data
              .filter(product => productIds.includes(product.id))
              .map(product => {
                const orderItem = orderItemResponse.data.data.find(info => info.productId === product.id);
                return {
                  ...product,
                  orderItemId: orderItem ? orderItem.id : null,
                  orderQuantity: orderItem ? orderItem.quantity : null,
                  orderPrice: orderItem ? orderItem.price : null,
                };
              });
            setProductsFiltered(filteredProducts);
          })
          .catch(productError => {
            console.error("Error fetching product data:", productError);
          });
      })
      .catch(orderItemError => {
        console.error("Error fetching cart item data:", orderItemError);
      });
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };


  const renderOrderItems = () => {
    return (
      <ul>
        {productsFiltered.map((item, index) => (
          <li key={index}>{item.title} - {item.orderQuantity} - ${item.orderPrice}</li>
        ))}
      </ul>
    );
  };

  return (
    <div>
      <Button onClick={openModal}>View</Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderOrderItems()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default OrderViewButton