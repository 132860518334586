import React from "react";
import Dashboard from "./Dashboard";
import { CartState } from '../Reducers/Context';
import { useEffect, useRef, useState } from 'react';
import bg2 from "../Images/bg2.jpg";
import SingleProduct from "./SingleProduct";


function Home(){
    const {
        state: { products },
        //productState: { sort, byStock, byFastDelivery, byRating, searchQuery },
      } = CartState();

      React.useEffect(() => {
        console.log("data from home",products);
      }, [products]);

    return(
        <div>
        {/* Background with blur */}
        <div style={{
            backgroundImage: `url(${bg2})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            filter: 'blur(8px)', // Only apply blur here
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: -1,
        }}></div>
    
        {/* Content without blur */}
        <div style={{ position: 'relative', zIndex: 1 }}>
            <Dashboard />
            <div className="home">
                <div className="productContainer">
                    {products.map((prod) => (
                        <SingleProduct prod={prod} key={prod.id} />
                    ))}
                </div>
            </div>
        </div>
    </div>
    )
}

export default Home;
