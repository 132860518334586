import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import OrderViewButton from './OrderViewButton';
//import { ROLE_ID } from '../globalConstants';

export default function OrderList() {
    const [orderList, setOrderList] = useState([]);
  
    useEffect(() => {
      Axios.get("http://localhost:8082/orderFetchAll")
        .then((response) => {
          const filteredOrders = response.data.data.filter(order => order.status === 1);
          setOrderList(filteredOrders);
          console.log("fil ord",filteredOrders);
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);

    //const roleId = sessionStorage.getItem("roleId")
    return orderList.map((order) => (
      <tr className="bg-warning">
        <th scope="row" className="px-6 py-4 font-weight-bold text-dark">
          {order.userId}
        </th>
        <td className="px-6 py-4">{order.firstName}</td>
        <td className="px-6 py-4">{order.lastName}</td>
        <td className="px-6 py-4">{order.mobile}</td>
        <td className="px-6 py-4">{order.email}</td>
        <td className="px-6 py-4">{order.tax}</td>
        <td className="px-6 py-4">{order.shipping}</td>
        <td className="px-6 py-4">{order.total}</td>
        <td className="px-6 py-4"><OrderViewButton order={order} /></td>
      </tr>
    ));  
}