import React from "react";
import Dashboard from "./Dashboard";
import CashierList from './CashierList';

export default function CashierPage(){
    return(
        <div>
            <Dashboard/>
            <h2 className='mt-2'>LIST OF ORDERS</h2>
            <div className="container mt-5">
                <div className="table-responsive mt-4">
                    <table className="table table-striped">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Order ID</th>
                                <th scope="col">First Name</th>
                                <th scope="col">Last Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Total</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <CashierList />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}