import React, { useEffect, useState } from "react";
import {Link, useNavigate } from "react-router-dom";
import { Button, Col, Card, Image, Row } from "react-bootstrap";
import { CartState } from '../Reducers/Context';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import axios from "axios";
import UpdateItem from "./UpdateItem";
import DeleteItem from "./DeleteItem";
import pay from "../Images/pay.jpg";

const Cart = () => {
  const navigate = useNavigate(); 

  const {
    state: { cart },
  } = CartState();

  const logout = () => {
    navigate('/');
  };

  const menu = () => {
    navigate('/home');
  };

  const [total, setTotal] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [cartItemData, setCartItemData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [user, setUser] = useState(null);
  var userId = parseInt(sessionStorage.getItem("userId"), 10);

  // Fetch user data
  useEffect(() => {
    if (!userId) {
      console.error("User ID is not available in session storage.");
      return;
    }

    axios.get(`http://localhost:8082/users/${userId}`)
      .then((response) => {
       const userData = response.data.data;
        setUser(userData);
        console.log("User data fetched:", userData); // Log fetched data
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [userId]);

  useEffect(() => {
    const totalPrice = filteredProducts.reduce((acc, curr) => acc + (curr.cartPrice || 0), 0);
    const totalQuantity = filteredProducts.reduce((acc, curr) => acc + (curr.cartQuantity || 0), 0);
    setTotal(totalPrice);
    setTotalQuantity(totalQuantity);
  }, [filteredProducts]);

  useEffect(() => {
    const cartId = parseInt(sessionStorage.getItem("cartId"), 10);

    axios.get(`http://localhost:8082/cartItemFetchByCartId/${cartId}`)
      .then(cartItemResponse => {

        const cartItms = cartItemResponse.data.data
        .filter(cartItem => cartItem.active == 0) 
        setCartItemData(cartItms);            

        const productIds = cartItms      
        .map(cartItem => cartItem.productId);
        
        //console.log("product ids", productIds)

        axios.get(`http://localhost:8082/product`)
          .then(productResponse => {
            const filProducts = productResponse.data.data
              .filter(product => productIds.includes(product.id))
              .map(product => {
                const cartItem = cartItms.find(info => info.productId === product.id);
                return {
                  ...product,
                  cartItemId: cartItem ? cartItem.id : null,
                  cartQuantity: cartItem ? cartItem.quantity : null,
                  cartPrice: cartItem ? cartItem.price : null,
                };
              });
            setFilteredProducts(filProducts);
          })
          .catch(productError => {
            console.error("Error fetching product data:", productError);
          });
      })
      .catch(cartItemError => {
        console.error("Error fetching cart item data:", cartItemError);
      });
  }, []);

  //console.log("prodsssss",filteredProducts)

  const updateCartItem = async (cartItem) => {
    try { 
      const response = await axios.put(`http://localhost:8082/cartItemUpdate/${cartItem.id}`, cartItem);
      console.log("printing cartItemData", cartItemData);
      console.log("Cart item with id ${cartItem.id} updated successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error updating cart item with id ${cartItem.id}:", error);
      throw error;
    }
  };
  
  const updateCartItems = async (cartItems) => {
    try {
      const updatePromises = cartItems.map(item => updateCartItem(item));
      const results = await Promise.all(updatePromises);
      console.log("All cart items updated successfully:", results);
    } catch (error) {
      console.error("Error updating cart items:", error);
    }
  };  
  const handleOrderItemCreate = async (product, orderId) => {
    const orderItemData = {
      productId: product.id,
      orderId: orderId.data.data.id,
      sku: product.sku, // Replace with actual SKU if available
      price: product.cartPrice,
      discount: product.discount, // Replace with actual discount logic
      quantity: product.cartQuantity,
      content: product.content
     // createdAt: Date.now(),
    };

    try {
   await axios.post("http://localhost:8082/orderItemCreate", orderItemData)
   .then();
      console.log("Order item created successfully:");
    } catch (error) {
      console.error("Error creating order item:", error);
    }
  };


  const handleCheckout = async (product) => {
   
    product.preventDefault();
    try {
     const orderData =  await axios.post("http://localhost:8082/orderCreate", {
        userId: userId,
        sessionId: "1",
        token: "3",
        status: 0,
        subTotal: total,
        itemDiscount: 1,
        tax: 1,
        shipping: 1,
        total: total,
        promo: "3",
        discount: 2,
        grandTotal: total,
        //createdAt: Date.now(),
        firstName: user.firstName,
        middleName: user.middleName,
        lastName: user.lastName,
        mobile: user.mobile,
        email: user.email
      });
      //await Promise.all(updateCartItems);
      const updatedCartItems = cartItemData.map(cartItem => ({ ...cartItem, active: true }));
      await updateCartItems(updatedCartItems);
      await Promise.all(filteredProducts.map(product => handleOrderItemCreate(product, orderData)));
      alert("Order Added Successfully", orderData.data);  
      navigate('/ThankYou'); 
      setShowMessage(true);
    } catch (error) {
      console.error("Error during checkout:", error);
    }
  };

  return (
    <div>
      <div style={{
        backgroundImage: `url(${pay})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        filter: 'blur(8px)',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: -1,
      }}></div>
      <div className="home">
        <Navbar bg="primary" data-bs-theme="dark">
          <Container>
            <Navbar className="me-auto" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Navbar.Brand onClick={menu} style={{ textTransform: 'uppercase', fontFamily: 'sans-serif' }}>QDine</Navbar.Brand>
                <Nav.Link onClick={menu} className="text-white" style={{ textTransform: 'uppercase', fontFamily: 'sans-serif', marginTop: '8px' }} href="#Home">Menu</Nav.Link>
              </div>
            </Navbar>
            <Nav className="justify-content-end">
              <Nav.Link onClick={logout}>Logout</Nav.Link>
            </Nav>
          </Container>
        </Navbar>
        <div className="productContainer mt-3">
          {filteredProducts.map((prod) => (
            <Card key={prod.id} className="mb-3 custom-card-size">
              <Card.Body>
                <Row>
                  <Col md={2}>
                    <Image src={prod.slug} alt={prod.title} fluid rounded />
                  </Col>
                  <Col md={2}>
                    <span>{prod.title}</span>
                  </Col>
                  <Col md={2}>
                    <span>$ {prod.cartPrice}</span>
                  </Col>
                  <Col md={2}>
                    <span>{prod.cartQuantity}</span>
                  </Col>
                  <Col md={2}>
                    <UpdateItem product={prod} />
                  </Col>
                  <Col md={2}>
                    <DeleteItem product={prod} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ))}

          <div className="filters summary text-center">
            <div className="mb-2">
              <span className="title" style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Subtotal ({totalQuantity}) items</span>
            </div>
            <div className="mb-3">
              <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Total: $ {total}</span>
            </div>
            <div>
            
         <Button type="button" onClick={handleCheckout}  className="mt-2">
             Proceed to Checkout
         </Button>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;