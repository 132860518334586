import React from 'react'
import axios from 'axios'
import { useEffect, useState } from "react";
import { Button, Col, Form, Image, ListGroup, Row, Modal } from "react-bootstrap";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";


export default function UpdateItem(props) {
    const [show, setShow] = useState(false);
    const [quantity, setQuantity] = useState('');
    const [inputType, setInputType] = useState('dropdown');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [cartItem, setCartItem] = useState();
    const product = props.product;

    const handleQuantityChange = (event) => {
        const value = event.target.value;
        setQuantity(value);
        if (value.length >= 10) {
          setInputType('text');
        } else {
          setInputType('dropdown');
        }
      };
    const generateDropdownOptions = () => {
        const options = [];
        for (let i = 1; i <= 10; i++) {
          options.push(<option key={i} value={i}>{i}</option>);
        }
        return options;
      };

    useEffect(() => {
        // Fetch cart data here using Axios or any other method
        // Example:
        axios.get(`http://localhost:8082/cartItemFetchSingle/${product.cartItemId}`)
          .then(response => {
            setCartItem(response.data);
          })
          .catch(error => {
            console.error("Error fetching cart item data:", error);
          });
      }, []);

    //console.log("single cart item", cartItem.data.productId);

     const editCartItemQuantity = (e) => {
        e.preventDefault();
        handleClose();
        console.log("edit item function called")
        console.log(product.cartItemId)
        axios.put(`http://localhost:8082/cartItemUpdate/${product.cartItemId}`, {
            "productId": product.id,
            "cartId": parseInt(sessionStorage.getItem("cartId"), 10),
            "sku": product.sku,
            "price": product.price * parseInt(quantity),
            "discount": product.discount,
            "quantity": parseInt(quantity),
            "active": false,
            "createdAt": "0001-01-01T00:00:00Z",
            "content": product.content,
        }).then((response) => {
            alert("Quantity Changed Successfully")
            window.location.reload();
        });
    }

  return (
    <div>
        <Button type="button" variant="light" onClick={handleShow}>
            <AiFillEdit fontSize="20px" />
        </Button>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Quantity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{product.slug}</p>
            <p>{product.title}</p> 
            {inputType === 'dropdown' ? (
            <Form.Control as="select" value={quantity} onChange={handleQuantityChange}>
              <option value="">Select Quantity</option>
              {generateDropdownOptions()}
            </Form.Control>
            ) : (
                <Form.Control type="text" placeholder="Enter Quantity" value={quantity} onChange={handleQuantityChange} />
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={editCartItemQuantity}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
