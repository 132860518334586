import React, { useState } from 'react';
import { Card, Form, Button, Container, Row, Col, Modal } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import axios from 'axios';
import chef from '../Images/chef.jpeg';
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import logo from '../Images/logo.png';
import bg2 from '../Videos/bg2.mp4';


function generateSessionId() {
    const timestamp = new Date().getTime(); // Get current timestamp
    const random = Math.random().toString(36).substr(2, 10); // Generate random string
    
    return timestamp.toString() + random; // Combine timestamp and random string
}

// Example usage
const sessionId = generateSessionId();
console.log('Session ID:', sessionId);
function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [roleId, setRoleId] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();


    const handleResetForm = () => {
        setEmail('');
        setPassword('');
        setShowSuccessModal(false);
        setErrorMessage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            setErrorMessage('Please fill in both email and password fields.');
            return;
        }
        // Clear any previous error messages
        setErrorMessage('');

        try {
            const response = await axios.post('http://103.186.185.127:8082/userlogin', {
                email: email,
                password: password,
            });
        
            const roleId = response.data.data.userInfo.roleId;
            const userId = response.data.data.userInfo.id;

            sessionStorage.setItem("userId", JSON.stringify(userId));
            sessionStorage.setItem("roleId", JSON.stringify(roleId));
        
            setRoleId(roleId);
            if (roleId === 2) {
                try {
                    await axios.get(`http://localhost:8082/cartFetchSingleByUserId/${userId}`);
                    console.log('Cart item already exists for this user.');
                } catch (error) {
                    // If cart item does not exist, create it
                    console.error('Error fetching cart item data:', error);
                    await axios.post('http://localhost:8082/cartCreate', {
                        userId: userId,
                        sessionId: generateSessionId(),
                        token: response.data.data.tokens.AccessToken.Token,
                        status: 0,
                        firstName: response.data.data.userInfo.firstName,
                        middleName: response.data.data.userInfo.middleName,
                        lastName: response.data.data.userInfo.lastName,
                        mobile: response.data.data.userInfo.mobile,
                        email: response.data.data.userInfo.email
                    });
                    console.log('Cart item created successfully.');
                }
            }
      
            
       
            // // Log response data
            // console.log("data"); 
             console.log(roleId);
            // console.log("data");  // Add this line
           
            if (roleId === 2) {
                setShowSuccessModal('Successful Login! Welcome Customer');
                navigate('/home');
                window.location.reload();

            } else if (roleId === 1) {
                setShowSuccessModal('Successful Login! Welcome Owner');
                navigate('/Users');
                
            } else if (roleId === 4) {
                setShowSuccessModal('Successful Login! Welcome Cashier');
                navigate('/CashierPage');
            }
            else if (roleId === 3){                
                setShowSuccessModal('Successful Login! Welcome Chef');
                navigate('/chefMenu');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // 401 Unauthorized: Incorrect password
                setErrorMessage('Incorrect password');
            }if(error=="Error fetching cart item data:"){
                
                    // If there's an error fetching data from cartItemFetchSingle
                    // Handle error silently without interrupting the flow
                    console.error('Error fetching cart item data:', error); // Optional: Log the error for debugging purposes
                  }
            
            else {
                // Handle other errors
                console.error('Login failed:', error);
                setErrorMessage('Login failed. Please try again.');
            }
        }
        
    };

    return (
        <div className="login-page position-relative">
      <video autoPlay muted loop className="bg-video position-absolute top-0 start-0 w-full h-full">
        <source src={bg2} type="video/mp4" />
      </video>

      <div className="icon-container position-absolute top-0 end-0 mt-3 me-3">
          {/* <img src={logoHD} className="w-12 h-auto filter brightness-0 invert"/> */}
        <a href="https://www.facebook.com" className="social-icon">
          <FaFacebook size={40} />
        </a>
        <a href="https://www.twitter.com" className="social-icon">
          <FaTwitter size={40} />
        </a>
        <a href="https://www.instagram.com" className="social-icon">
          <FaInstagram size={40} />
        </a>
      </div>

      <Container className="d-flex justify-content-center align-items-center position-relative" style={{ minHeight: "100vh" }}>
        <div className="login-form position-absolute">
          <Card style={{ width: '600px', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
            <Card.Body>
              <div className="text-center mb-4" >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={logo} alt="Restaurant Logo" style={{ width: '50%', borderRadius: 50 }} />
                </div>
                <Row>
                  <Col xs={12} className="text-center mt-3">
                    <h1 style={{fontWeight: 700}}>Welcome</h1>
                  </Col>
                </Row>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label style={{fontSize: 20, fontFamily: 500}}>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formPassword">
                    <Form.Label style={{fontSize: 20, fontFamily: 500}}>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  {errorMessage && (
                    <div className="alert alert-danger">{errorMessage}</div>
                  )}
                  <Button variant="primary" type="submit" className="w-100" style={{ marginTop: '10px' }}>
                    Login
                  </Button>
                  <div className="text-center mt-2">
                    Don't have an account?
                    <Link style={{ padding: '10px' }} to="/register">Register Here</Link>
                  </div>
                </Form>
                        <Modal show={showSuccessModal} onHide={handleResetForm}>
                            <Modal.Header closeButton>
                                <Modal.Title>Success!</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {roleId === 2 ? 'Welcome Customer' : roleId === 1 ? 'Welcome Admin' : 'Welcome Owner'}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={handleResetForm}>
                                    Close
                                </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
    );
}

export default Login;