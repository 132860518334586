import React from 'react';
import UserList from './UserList';
import { useNavigate } from 'react-router-dom';
import Dashboard from './Dashboard';
import { Table, Card } from 'react-bootstrap';


export default function Users() {
  const navigate = useNavigate();

  const addUser = () => {
    navigate('/AddUser');
  };

  return (
    <div className='soft-gradient-bg'>

    <Dashboard />
    <div className="container-fluid mt-5">
      
      <div className="d-flex justify-content-center">
        <button type="button" onClick={addUser} className="btn btn-primary mb-2">Add New User</button>
      </div>
      
      <Card.Body className='mt-3'>
        <div className="table-responsive">
          <Table hover size="sm">
            <thead className="thead-light">
              <tr className='hover-pop-effect justify-items-center'>
                <th>First Name</th>
                <th>Middle Name</th>
                <th>Last Name</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Password</th>
                <th>Intro</th>
                <th>Profile</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <UserList />
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </div>
    </div>

  );
}
