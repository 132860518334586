import React, { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import axios from "axios";
import Card from 'react-bootstrap/Card';
import pay from "../Images/pay.jpg";

const MyOrders = (props) => {
  const navigate = useNavigate();  // Correct placement of useNavigate
 
  const user = props.user;
  const [id, setId] = useState(user.id);
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);

  const logout = () => {
    navigate('/');
  }

  const menu = () => {
    navigate('/home');
  }

  const myOrders = () => {
    navigate('/MyOrders');
  }

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        // Fetch order data from orders table
        const orderResponse = await axios.get(`http://localhost:8082/orderFetchSingle/${id}`);
        const orders = orderResponse.data;

        // Fetch order items data from order_items table
        const orderItemsResponse = await axios.get(`http://localhost:8082/orderItemFetchSingle/${id}`);
        const orderItems = orderItemsResponse.data;

        // Fetch product data from product table
        const productResponse = await axios.get(`http://localhost:8082/product/${id}`);
        const products = productResponse.data;

           // Combine data to create order details
           const orderDetails = orders.map(order => {
            const items = orderItems
              .filter(item => item.order_id == order.id)
              .map(item => {
                const product = products.find(product => product.id == item.product_id);
                return product.title;
              });
            return {
              id: order.id,
              date: order.updated_at, // Assuming updated_at is the date of order
              items: items
            };
          });
  
          setOrderData(orderDetails);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching order data:', error);
          setLoading(false);
        }
      };
  
      fetchOrderData();
    }, []);

  return (
    <div>
        {/* Background with blur */}
        <div style={{
            backgroundImage: `url(${pay})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            filter: 'blur(8px)', // Only apply blur here
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: -1,
        }}></div>
    <div className="home">
    <div>
        <Navbar bg="primary" data-bs-theme="dark">
          <Container>
          <Navbar className="me-auto" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
    <Navbar.Brand onClick={menu} style={{ textTransform: 'uppercase', fontFamily: 'sans-serif'}}>QDine</Navbar.Brand>
    <Nav.Link onClick={menu} className="text-white" style={{textTransform: 'uppercase', fontFamily: 'sans-serif', marginTop:'8px'}}>Menu</Nav.Link>
  </div>
    </Navbar>
          <Nav className="justify-content-end">
              <Nav.Link onClick={logout}>Logout</Nav.Link>
          </Nav>
          </Container>
        </Navbar>
    </div>
    </div>
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {orderData.map(order => (
            <Card key={order.id} style={{ marginBottom: '10px' }}>
              <Card.Body>
                <Card.Title>Order Details</Card.Title>
                <Card.Text>
                  <p>Date of Order: {order.date}</p>
                  <p>Items Ordered:</p>
                  <ul>
                    {order.items.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
      )}
    </div>
    </div>
    
  );
};

export default MyOrders;
